#sumbit_create_btn{
padding: 1% !important;
}
.fontfamilyAver{
  font-family: "Averia";
}
.Mob_Otp_popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px 20px 0 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 370px;
  width: 90%;
  text-align: center;
}
.Mob_Otp_popup .input-field {
  margin-bottom: 20px;
}
.Mob_Otp_popup img{
  cursor: pointer;
  position: absolute;
  right: -8px;
  top: -6px;
}
.Mob_Otp_popup .input-field input[type="tel"] {
  padding: 10px;
  width: calc(100% - 20px); /* Adjust for padding */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
/* OTPInput.css */

.otp-container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 300px; /* Adjust width as needed */
}
.otp-container li {
list-style: none;
}

.otp-input {
  width: 40px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
  text-align: center;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 5px;
}

/* Style for focused input */
.otp-input:focus {
  outline: none;
  border-color: #a2c760; /* Change color as needed */
}

.Mob_Otp_popup .input-field button {
  padding: 5px 20px;
  margin-bottom: 0;
  background-color: #a2c760;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}
.ViewInputMob{
  padding-top: 20px !important;
}
.viewotpInput{
  padding-top: 20px;
}
.Mob_Otp_popup .input-field button:hover {
  background-color: #739e28;
}
.book_event_dolally{
  border: none;
  border-radius: 7px;
  padding: 2px 20px 2px 20px;
  height: 40px;
}
.check-btn-pin {
  width: 120px;
  background-color: #a2c760;
  color: #ffffff;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 15px;
  text-align: center;
  border-radius: 5px;
  padding: 1.2%;
}
.add_more_brnch{
  font-size: 14px;
  cursor: pointer;
  color: #a2c760;
  letter-spacing: 1.5px;
}
.errorBrunch{
  color: #e11d48;
  font-size: 13px;
  text-align: center;
  left: 30%;
  position: absolute;
}
.book_event_dolally:hover{
  background-color: #a2c760;
  color: #ffffff;
}
.brunch-container {
  flex-wrap: wrap;
}

.brunch-card {
  /* background-color: #1a1a1a; */
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  /* text-align: center; */
  width: 100%;
  border: 1px solid #827c7c;
  /* margin: 20px; */
}

.brunch-title {
  color: #a2c760;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 5px;
  text-align: left !important;
}

.brunch-name {
  padding-top: 5px;
  color: #050100;
  font-size: 18px;
  line-height: 12px;

}

.brunch-description {
  font-size: 14px;
  line-height: 12px;
  color: #827c7c;
  padding-bottom: 5px;
}

.brunch-price {
  font-size: 16px;
  margin-bottom: 10px;
}
.brunch-price span{
  color: #827c7c;
  font-size: 12px;
  padding-top: -10px;
  line-height: 0;
}


.food-menu-container {
  padding: 2%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  box-shadow: 2px 5px 5px 5px #f5f5f5;
  margin-bottom: 2%;
}
.offersicon{
color: #a2c760 ;
}
.offerspan:hover{
  color: #a2c760 ;
  cursor: pointer;
  font-weight: 400;
}
.cartpgnme{
  padding: 0 5%;
}
#dfafgfsa{
  border-radius: 4px;
  color: white;
  background-color: #a2c760 !important;
}
.shadow-zoom-effect {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* transition: transform 0.3s ease-in-out; */
}

/* Apply the zoom effect on hover */
/* .shadow-zoom-effect:hover {
  transform: scale(1.1);
} */
.minus{
  cursor: pointer;
}
.plus{
  cursor: pointer;
}

.box_padding29{
  padding: 2% 2% 1% 4%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-box {
  position: relative;
  cursor: pointer;
  margin-top:10px ;
  display: flex;
  background-color: #fff;
  box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  overflow: hidden;
  /* width: 300px; */
  transition: transform 0.2s ease-in-out;
}
#cart-box{
  border-left: none;    /* Adjust color and thickness as needed */
  border-right: 0px solid #000;   /* Adjust color and thickness as needed */
  border-top: 0px solid #000;     /* Adjust color and thickness as needed */
  border-bottom: 1px solid #f2f2f2;
  /* position: relative; */
  cursor: pointer;
  margin-top:0px ;
  /* display: flex; */
  background-color: #fff;
  /* box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* border-radius: 8px; */
  overflow: hidden;
  /* width: 300px; */
  transition: transform 0.2s ease-in-out;
}

.cart-box:hover {
  transform: scale(1.05);
}

.product-image img {
  width: 100%;
  min-height: 100%;
  max-height: 100px;
  object-fit: cover;
}

.product-details {
  padding-left: 7px;
  padding: 2% 2% 2% 6%;

}

.product-name {
  /* margin-right: 60px !important; */
  /* font-size: 1.2rem; */
  font-size: 15px;
  color: #333;
}

.product-price {
  bottom: -2px;
  position: absolute;
  font-size: 1rem;
  color: #555;
  /* left: 234px; */
  right: 4%;
}

.cart-box label {
  display: block;
  margin-top: 0px;
  color: #777;
}
.d_order_borderbtm{
  padding-bottom: 4% !important; 
  padding: 0 4%;
  max-height: 305px;
  overflow-y: scroll;
}
/* .d_order_borderbtm::-webkit-scrollbar {
  display: none;
} */
.add_more_item{
  margin-top: 4% !important;
  background-color: rgb(241, 241, 241);
  padding: 5px;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: -5px;
  margin: 0 auto;
    width: 43%;
}
.subTotalProce{
  padding-left: 10px;
}
.subTotalProce p{
font-size: 13px;
line-height: 14px;
}
.subTotalProce span{
  padding-left: 5px;
}
#sizeOptionModal{
  font-weight: 400;
  font-size: 15px;
  color: white;
  border-radius: 6px;
  padding: 5px 8px 5px 8px;
  background-color: #a2c760;
  margin-bottom: 5px;
}

.food-menu-container .food-details {
  flex: 1;
  padding-right: 0.6%;
}

.food-menu-container .description {
  width: 70%;
  font-size: 14px;
  color: grey;
}

.food-menu-container .image-container {
  position: relative;
  align-items: center;
  width: 129px;
  height: 120px;
  margin-bottom: 3%;
}

.food-menu-container img {
  height: 100%;
  border-radius: 5px;
}

.food-menu-container .sold-out {
  color: red;
}

.food-menu-container .quantity-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.food-menu-container .quantity-container span {
  padding: 0.2em 0.5em;
  background-color: #edf7dd;
  color: #759246;
  font-weight: bold;
  cursor: pointer;
}

.food-menu-container button ,.quantity-container{

  width: 100px;
  background-color: #edf7dd;
  border: none;
  outline: none;
  position: absolute;
  color: #759246;
  border-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -8%;
}
.food-menu-container .quantity-container input {
  width: 50px;
  height: 30px;
  padding: 5px;
  text-align: center;
  border: none;
  background-color: #edf7dd;
  color: #759246;
  font-weight: bold;
  /* margin: 0 3px; Add some margin to the input */
}

.addOnItems_block{
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border: 1px solid #a2c760;
  border-radius:10px ;
  margin-bottom: 10px;
  padding: 1.5%;
padding-left: 2%;
}
.Addoncustom-checkbox{
  transform: scale(1.5);
}
.Addoncustom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 12px;
  height: 12px;
}

.Addoncustom-checkbox:checked {
  background-color: #fff; /* White background */
  border-color: #28a745; /* Green border */
}

.Addoncustom-checkbox:checked::before {
  content: '✔'; /* Unicode character for checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #28a745; /* Green tick color */
}


.search-dropdown {
  position: absolute;
  list-style: none;
  background-color: white;
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
}
#mainCartPage p{
margin-bottom: 0 !important;
}
.offer_coupons{
  /* height: 120px; */
  position: relative;
  /* background-color: aliceblue; */
  border: 1px solid #a2c760;
  border-radius: 10px;
  text-align: center;
  padding: 2%;
  margin:3%
}
#titlemr{
  padding-left: 2.5%;
}
#titlemr2{
  padding-left: 4.5%;
}
#titlemr3{
  padding-bottom: 1%;
  padding-left: 5%;
  line-height: 1px !important;
}
#titlemr4{
  padding-left: 5.2%;
  line-height: 2px !important;
}
.search-dropdown {
  display: block;
}


#margin_home{
  margin:0px !important;
}
#cart_address input{
width:98%;
margin:5px
}
#cart_btn2{
  width: 43% !important;
}
#food-web_loc{
  margin-top: 0 !important;
}

.h-food-menuu{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; 
  align-items: center;
}
.location_resturant {
  padding: 5px 10px 0 10px;
  height: 29px;
  background-color:#edf7dd;
  color: #759246;
  border-radius: 25px;
  font-size:12px;
  flex-wrap: wrap; 
  margin-top: -25px;
  margin-bottom: 10px;
}
.food_items{
  height:400px;
  overflow: scroll;
}

.merch_items{
  height:520px;
  overflow-y: scroll;
  overflow-x:hidden;
}
.beer_items{
  height:366px;
  overflow-y: scroll;
  overflow-x:hidden;
}
#mob_res_loc{
  padding: 0 !important;
    margin-top: 0 !important;
    background-color: #ffffff !important;
}
#mob_res_loc_food{
  margin-bottom: 0px !important;
}
.cart-form  p{
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.error_coupon{
  font-size: 12px;
  color:#e11d48;
  margin-top: -10px;
  margin-left: 6%;
  padding-bottom: 10px;
}

.error_coupon2{
  font-size: 12px;
  color:#e11d48;
}
.valid_coupon{
  padding-bottom: 10px;
  font-size: 12px;
  color:#a2c760;
  margin-top: -10px;
  margin-left: 6%;
}
.line_height_control{
  margin-bottom: 0px;
}
.sh_event_list_peek{
  height:860px;
  overflow: scroll;
}
.sh-2{
  height:280px;
  overflow: scroll;
}
@media only screen and (min-width: 992px) {ctedFilter
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  #file-uplod-img{
    width: 100% !important;
    height: 389px;
  }
  body {
    font-family: "Averia";
    background-color: #f2f2f2;
  }
.scrollbar::-webkit-scrollbar {
  display: none;
}
#fnb-category li .active {
  background-color: #a2c760;
  color:#ffffff;
  cursor:zoom-in;
}

#fnb-category{
  cursor: pointer;
}
  .ds-n{
    display: none;
  }
  p.LoginForm_formFieldErrorMessage__Q\+HoA {
    font-size: 13px;
  }

.historyData td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;

}

/* F&B section 14/01/23 */

.cart-container {
  position: relative;
  width: 80px;
  
}

.cart-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: brightness(0.9) saturate(1.2) contrast(0.9) blur(1px);
}

.cart-qty-indicate {
  position: absolute;
  top: 30%;
  left: 100%;
  transform: translate(-50%, -50%);
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: #a2c760;
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), 
              0px 0px 10px rgba(0, 0, 0, 0.1), 
              0px 0px 20px rgba(0, 0, 0, 0.1), 
              0px 0px 40px rgba(0, 0, 0, 0.1); */
}

/* .cart-qty-indicate::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: -20px;
  left: -10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #;
  opacity: 0.9;
  
} */

/* 
#cart-qty-indicate {
  position: relative;
  display: inline-block;
}

#cart-qty-indicate::before {
  content: '';
  position: absolute;
  z-index: -1;
  left: -10px;
  top: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #a2c760;
  opacity: 0.5;
  filter: blur(3px);
  color: ;
}

#cart-qty-indicate::after {
  content: attr(data-count);
  position: absolute;
  z-index: 1;
  top: -8px;
  right: -12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #a2c760;
  color: white;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
} */

  /* 30/11/22 */

  .custom-control {
    font-weight: 700;
    border: 1px solid #ced4da;
    border-radius: 5px;
    width: 83%;
    padding: 1% 2% 1% 2%;
    display: flex;
    /* background-color: #15824B; */
    /* margin-top: 2%; */
    /* margin-left: 0; */
  }
  #btn-big-event-card {
    width: 34px;
    height: 30px;
    margin: 0% !important;
    margin-top: 3px !important;
  }
  p.d_event_name {
    margin-bottom: 0rem;
  }
  #l-icon {
    width: 12%;
  }
  #location-section {
    min-height: 480px;

    max-height: 900px;
  }

  ul {
    list-style-type: none;
  }

  span#basic-addon2 {
    background-color: #a2c760 !important;
    cursor: pointer;
    margin-left: 20px;
    color: white;
  }
  .modal-90w-cart {
    width: 30%;
    max-width: none !important;
    position: fixed;
    /* bottom: 0; */
    top: 0;
    left: "auto";
    right: 0%;
  }

  /* 05/12/22 */

  .search-container button {
    background-color: white;
    border: none;
    cursor: pointer;
    color: #a2c760;
  }
  .search-container input {
    border: none;
    outline: none;
    width: 93%;
    padding: 1%;
  }
  .search-container form {
    border: 2px solid #a2c760;
    border-radius: 10px;
  }

  /* 05 end 22 */
  .delivery-address input[type="radio"] {
    margin: 2%;
  }
  .delivery-address input[type="text"] {
    margin-bottom: 3%;
  }
  .delivery-address label {
    font-size: large;
  }
  .cart-pg {
    width: 100%;
    /* width: 100%; */
    height: 440px;
    margin: 2% auto;
    background-color: white;
    overflow: scroll;
    position: relative;
  }
  #sinup_cart input {
    margin-left: 2%;
  }
  /* 30/11/22 */

  #option-select {
    background-color: #a2c760;
  }
  .d_book_event {
    background-color: #edf7dd;
    opacity: 90%;
    outline: none;
    border: none;
    width: 115px;
    margin-right: 4%;
    border-radius: 4px;
    color: #759246;
  }
  button.d_book_event {
    height: 110%;
  }
  .share-like {
    background-color: #f2f2f2;
    border: none;
    border-radius: 5px;
    padding: 2px 20px 2px 20px;
  }

  .share-like:hover {
background-color: #a2c760;
color: #ffffff;
  }
  .notify-me {
    height: 24px;
    text-align: center;
    border-radius: 5px;
    background-color: #a2c760;
    color: white;
    margin: 2%;
  }

  #t-icon {
    margin-left: auto;
    margin-top: 5px;
    cursor: pointer;
  }
  #t-icon:hover {
    color: #00acee;
  }

  .sticky-header {
    top: 0;
    position: sticky;
    z-index: 1;
    
      -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  
  }
  .adj-location {
    text-align: center;
    cursor: pointer;
  }
  .adj-location h6 {
    margin-top: 10px;
  }
  ul.catgry-beer {
    padding-left: 0rem;
  }
  .catgry-beer li {
    display: block;
    padding: 13px 30px;
    border: 1px solid #a2c760;
    border-radius: 5px;
    font-size: 1.1rem;
    position: relative;
    margin: 2%;
  }
  .catgry-beer li:hover,
  .catgry-beer li.active {
    background-color: #a2c760;
    color: rgb(241, 237, 237);
  }

  .food-menu {
    border-top: 1px dotted #c5c6d0;
    padding: 2%;
  }
  .d_beer {
    margin-top: -18px;
    /* min-height: 93.5vh; */
  }

  .d_food {
    margin-top: -18px;
    min-height: 58vh;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 15px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: " ";
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  #n-veg input:checked + .slider {
    background-color: #cd4518;
  }

  input:checked + .slider {
    background-color: #a2c760;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #a2c760;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(22.7px);
  }
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  ul.d_order_list_name.mb-0 {
    padding-left: 0rem;
  }
  .d_feat_event_cnt ul li {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
  }

  /* for font */

  @font-face {
    font-family: "Dirty Headline";
    src: url("../fonts/Dirty\ Headline.ttf");
  }

  @font-face {
    font-family: "Averia";
    src: url("../fonts/AveriaSerifLibre-Regular.ttf");
  }

  #Averia_font {
    font-family: "Averia";
  }
  #dirty_hedline_font {
    font-family: "Dirty Headline";
  }

  ul.d_feat_event_list {
    padding-left: 0rem;
  }

  .review_pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: -10px;
    margin-bottom: 12px;
    border: solid 3px white;
  }

  .review_mobile_view {
    border-radius: 7px;
    width: 95%;
    background-color: #f1f1f1;
    color: #5b5b5b;
    border: none;
    outline: none;
    opacity: 80%;
  }



  #testimonial-desktop {
    margin: 2% 3% auto;
    background-color: rgb(244, 243, 243);
    /* color: ; */
  }

  /*  for entry 18 */

  #entry-gate {
    margin: 150px auto;
    /* width: 450px; */
    width: 567px;
    /* height: 320px; */
    height: 385px;
    box-shadow: 2px 5px 3px #888888;
    background-color: white;
    border-radius: 10px;
    /* padding: 1%; */
    /* text-align: center; */
    text-align: center;
  }

  #btn-18 {
    width: 100px;
    /* padding: 2% 12% 2% 12%; */
    height: 34px;
    outline: none;
    border: 1px solid #cdc8c8;
    background-color: #fff;
    color: black;
    text-transform: none;
    font-family: "Averia" !important;
    text-align: center;
    margin: 0px 12px auto;
    border-radius: 5px;
  }
  #btn-18:hover {
    background-color: #a2c760;
    color: #fff;
  }
  .d_linee {
    width: 100px;
    height: 3.5px;
    background-color: #9bc059;

    display: inline-block;

    vertical-align: middle;
  }
  /* for home page */

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
width: 50px;
  }
  /* for Header */
  #mug-c-card img {
    width: 100%;
    height: auto;
    border-radius: 1px;
  }
  .card img {
    /* width:3rem;
    height: 3rem;
    border-radius: 50%; */
    float: right;
  }
  .card-text {
    float: left;
    top: 400px;
  }

  .h-main {
    background-color: white;
    position: sticky;
    z-index: 1;
    padding: 0.9%;
    height: 70px;
    box-shadow: 1px 1px 5px #c0c0c0b9;
  }
  .h-main img {
    cursor: pointer;
  }

  .h-left img {
    width: 10%;
    padding: 2%;
  }
  .h-center {
    text-align: center;
  }
  .h-right img {
    width: 20px;
    padding-right: 5px;
  }
  .h-right {
    text-align: end;
    padding-top: 8px;
  }
  .h-left {
    padding-top: 8px;
  }

  /* searchBar */

  .s-main {
    padding: 1%;
    background-color: white;
    justify-content: center;
    text-align: center;
  }

  .serachbar {
    border: 2px solid #a2c760;
    display: flex;
    border-radius: 10px;
    position: relative;
  }

  .serachbar input::placeholder,
  .filter-box input::placeholder {
    font-size: 14px;
    padding: 2%;
  }

  .serachbar button {
    border: none;
    position: absolute;
    right: 5px;
    background: transparent;
    top: 8px;
  }
  #dropdown-basic {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
  }
  #dropdown-basic {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
  }

  .serachbar button img {
    width: 21px;
    position: relative;
    margin-top: -23%;
    right: 3px;
  }

  /* main section */

  .Main {
    /* background-color: #A2C760; */
    height: 800px;
  }

  .box_padding {
    padding: 20px;
  }

  .d_product_box {
    background: #fff;

    box-shadow: 0px 1px 6px rgb(0 0 0 / 15%);

    border-radius: 5px;

    /* overflow: hidden; */

    position: relative;
  }
  .d_product_box figure {
    height: 96px;
  }
  .d_product_img img,
  .d_days_iner_box figure img,
  .d_featured_events_box figure img,
  .d_event_sidepanel figure img,
  .d_card_icon img {
    width: 100%;
    border-radius: 5px;
    height: 100%;
cursor: pointer;
    object-fit: cover;
  }

  .d_product_img {
    width: 100%;

    height: 126px;
  }

  .d_main_panel {
    padding: 40px 0;

    /* min-height: 79vh; */
  }
  .d_main_panel_review {
    padding-top: 15px;
    /* min-height: 100vh; */
  }

  .d_hangout {
    margin-top: -18px;
    max-height: 74vh;
  }
  .gray_bg_color {
    /* background: #F2F2F2; */
    background: white;

    box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);

    border-radius: 5px;
  }

  .d_title_box {
    margin-bottom: 24px;
  }
  .d_main_title {
    font-weight: 800;

    /* font-size: 100%; */

    line-height: 24px;

    color: #221e20;

    margin-bottom: 4px;
  }

  .d_main_sub_title {
    font-weight: 400;

    font-size: 18px;

    line-height: 22px;

    color: #9bc059;
  }

  .d_line,
  .d_round {
    background-color: #9bc059;

    display: inline-block;

    vertical-align: middle;
  }

  .d_line {
    width: 40px;

    height: 2.4px;
  }

  .d_round {
    width: 2.4px;

    height: 2.4px;

    border-radius: 50%;

    margin-left: 3px;
  }

  .d_product_title {
    font-weight: 400;

    font-size: 100%;

    line-height: 21px;

    text-align: center;

    color: #5c5c5c;

    padding: 10px 0;
  }

  .d_locat_name,
  .d_cnt_locat,
  .d_feat_event_title,
  .d_product_title,
  .d_overlay p,
  .d_days_title,
  .d_feat_event_btn a {
    letter-spacing: 0.01em;
  }

  .d_locat_name {
    font-size: 14px;
    line-height: 21px;
    color: #222222;
    margin: 4px 0;
  }

  .d_days_title {
    font-size: 18px;
    line-height: 22px;
    color: #221e20;
    margin-bottom: 10px;
  }

  .d_product_title {
    font-weight: 400;

    font-size: 100%;

    line-height: 21px;

    text-align: center;

    color: #5c5c5c;

    padding: 10px 0;

    /* font-family: 'Poppins', sans-serif; */
  }

  #whatsonTap .d_product_title{
    
    padding: 0px 0px 10px 0!important;
  }
  
  #whatsonTap figure{
height: 100% !important;
  }
  .d_overlay {
    position: absolute;

    left: 0;

    right: 0;

    top: 0;

    bottom: 0;

    overflow: hidden;

    width: 100%;

    height: 100%;

    transition: 0.5s ease;

    background: rgba(162, 199, 96, 0.95);

    box-shadow: 0px 1px 6px rgb(0 0 0 / 15%);

    border-radius: 5px;

    display: flex;

    align-items: center;

    justify-content: center;

    opacity: 0;
  }

  .d_overlay p {
    font-weight: 700;

    font-size: 13px;

    line-height: 16px;

    text-align: center;

    text-transform: uppercase;

    color: #fff;
  }

  .d_product_box:hover .d_overlay {
    opacity: 1;
  }

  .d_right_panal {
    background: #fff;

    box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);

    border-radius: 5px;
  }

  .d_days_iner_box {
    display: flex;
  }

  .d_days_iner_box figure {
    width: 90px;

    height: 60px;

    margin-right: 12px;
  }

  .d_days_iner_box figure img {
    border: 1px solid #d8d8d8;

    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.15));

    border-radius: 5px;
  }

  .d_right_panal {
    background: #fff;

    box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);

    border-radius: 5px;
  }

  .d_locat_boxx {
    background: #fffcf1;
    /* border: 1px solid rgb(58, 55, 55); */
    /* box-shadow: 0px 2px 6px rgb(0 0 0 / 25%); */
    border-radius: 5px;
    width: 180px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    overflow: hidden;
    margin: auto;
    position: relative;
  }

  .d_locat_cnt img {
    display: inline-block !important;
  }

  .location-section {
    /* width: 90%; */
    /* border: 15px solid green; */
    /* padding: 50px; */
    margin: 0 2% 5% 2%;
    padding-bottom: 2%;
  }

  .footer {
    /* position: fixed; */
    padding: 3%;
    left: 0;
    bottom: 0;
    width: 100%;
    /* height: auto; */
    background-color: white;
    color: black;
    /* text-align: center; */
    /* margin: auto; */
    /* border-bottom-style: solid;
    border-bottom-width: medium; */
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  #edit-event{
    width: 650px !important;
  }

  #end-footer {
    border-top: 1px solid rgba(11, 11, 11, 0.27);;
    /* color:  */
    background-color: white;
    width: 100%;
  }

  /* evnt details */

  .black_heading .d_main_sub_title {
    font-size: 24px !important;
    line-height: 29px !important;
    color: #221e20 !important;
  }

  .black_heading .d_line,
  .black_heading .d_round {
    background-color: #221e20 !important;
  }

  .d_feat_event_cnt {
    padding: 17px 17px 0px 17px;
    height: 200px;
  }

  /* .poppins {
    font-family: 'Poppins', sans-serif !important;
} */

  .d_feat_event_title {
    height: 52px;
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .d_feat_event_title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 27px;
    color: #221e20;
    margin-bottom: 4px;
  }
  .d_event_name {
    font-size: 1rem;
  }

  .d_feat_event_name {
    font-size: 16px;
    color: #678a29;
  }

  .d_art_craft {
    margin: 26px 0;
  }

  .d_art_craft span {
    box-shadow: 0px 0px 4px #a2c760;
    border-radius: 5px;
    background-color: #fff;
    padding: 11px 20px;
    display: inline-block;
    margin-right: 18px;
    font-size: 15px;
  }

  .d_art_craft span img {
    vertical-align: sub;
    margin-right: 4px;
  }

  .d_art_craft p {
    line-height: 27px;
  }

  .p_gray {
    color: #636363 !important;
    font-size: 14px;
  }

  .d_title_box .p_gray {
    margin-top: 7px;
  }

  .hangout_dtl .p_gray {
    line-height: 26.62px;
  }

  .d_comn_btn:hover {
    background-color: #a2c760;
    color: #fff;
  }

  .d_comn_btn {
    display: inline-block;
    text-align: center;
    background-color: #a2c760;
    /* text-transform: uppercase; */
    color: #fff;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    border: 0;
  }

  .d_private_party .d_comn_btn {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    max-width: 215px;
    margin: 0 auto;
    padding: 12px 0;
  }

  .event_dtl_footer_list li {
    display: inline-block;
    text-align: center;
    margin-left: 20px;
  }

  .event_dtl_footer {
    border-top: 1px solid #d4d4d4;
    border-bottom: 1px solid #d4d4d4;
    background-color: #fff;
    padding: 10px 16px;
  }

  .event_dtl_footer_cnt p {
    color: #221e20;
    font-size: 15px;
  }

  .event_dtl_footer_list li a {
    color: #999999 !important;
    font-size: 13px;
  }

  .event_dtl_footer_list li .fa {
    font-size: 17px;
    vertical-align: bottom;
  }

  .event_dtl_footer_list {
    margin: 0;
  }
  .d_event_sidepanel figure img,
  .d_card_icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .d_event_sidepanel {
    box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }

  .d_event_sidepanel figure {
    width: 100%;
  }

  .d_btn {
    padding: 15px 0;
    display: block;
    text-align: center;
    background-color: #a2c760;
    /* text-transform: uppercase; */
    color: #fff;
    font-size: 17px;
    transition: all 0.3s ease-in-out;
  }

  .d_btn:hover,
  .d_comn_btn:hover {
    background-color: #a2c760;
    color: #fff;
  }

  /* event list */

  .d_find_event {
    margin-bottom: 25px;
  }

  .d_find_event .d_main_sub_title {
    font-size: 24px;
    line-height: 29px;
    color: #221e20;
  }

  .d_find_event .d_line,
  .d_find_event .d_round {
    background-color: #221e20;
  }

  .d_featured_events_box figure img {
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.15));
    border-radius: 5px 5px 0px 0px;
  }

  .d_featured_events_box {
    box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);
    border-radius: 5px;
  }
  .d_featured_events_box figure img {
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.15));
    border-radius: 5px 5px 0px 0px;
  }
  .d_feat_event_list li {
    font-size: 1rem;
    /* padding: 2%; */
    color: #636363;
  }

  .d_feat_event_list li img {
    width: 16px;
  }

  .d_feat_event_btn {
    border-top: 1px solid #dbe3e7;
    display: flex;
    align-items: center;
  }

  .d_feat_event_btn a {
    width: 50%;
    padding: 13px 0;
    text-align: center;

    font-size: 14px;
  }

  /* Event Save */

  .d_create_event {
    width: 500px;
    margin: 0 auto;
  }

  .d_upload_img {
    box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
    border-radius: 5px;
    margin-top: 40px;
  }

  .file-upload-btn:active {
    border: 0;
    transition: all 0.2s ease;
  }

  .file-upload-content {
    display: none;
    text-align: center;
  }

  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }

  .image-upload-wrap {
    position: relative;
  }

  .image-title-wrap {
    padding: 0 15px 15px 15px;
    color: #222;
  }

  .drag-text {
    text-align: center;
  }

  .drag-text img {
    margin-bottom: 5px;
  }

  .drag-text h3 {
    font-weight: 100;
    text-transform: uppercase;
    color: #15824b;
    padding: 60px 0;
  }

  .file-upload-image {
    padding: 2px;
    width: 900px;
  }

  .d_create_event_form {
    padding: 18px 0 0 0;
  }

  /* .form-control {
        border: 1px solid #E0E0E0 !important;
        border-radius: 5px;
        height: 50px;
    } */

  .d_free_or_paid {
    padding-top: 20px;
  }

  .d_free_or_paid .d_title_box {
    margin-bottom: 18px;
  }

  .d_free_or_paid .d_main_title {
    font-size: 18px;
  }

  /****custome radio******/

  .d_custome_raido {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .d_custome_raido input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .d_custome_raido .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  }

  .d_custome_raido:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .d_custome_raido input:checked ~ .checkmark {
    background-color: #fff;
  }

  .d_custome_raido .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .d_custome_raido input:checked ~ .checkmark:after {
    display: block;
  }

  .d_custome_raido .checkmark:after {
    top: 3.2px;
    left: 3.2px;
    width: 11.6px;
    height: 11.7px;
    border-radius: 50%;
    background: #a2c760;
  }

  /* media query for tab or less screen device */

  /* private event  */

  .d_private_event .d_main_sub_title {
    font-weight: 500;
    font-size: 15px;
    color: #65a126;
  }
  .form_label {
    font-size: 16px;
    color: #221d20;
    margin-bottom: 10px;
  }

  .location_select .form-control {
    background-color: transparent;
    z-index: 2;
    position: relative;
  }

  .location_select {
    position: relative;
    background-color: #fff;
  }

  .location_select::after {
    content: "";
    /* background-image: url(../images/icons/down-arrow.svg); */
    display: block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    position: absolute;
    top: 18px;
    right: 18px;
    z-index: 1;
  }

  .d_find_event .d_product_box {
    background: transparent;
    box-shadow: none;
    overflow: hidden;
    position: relative;
  }

  .d_find_event .d_hangout_box .d_product_img {
    height: 170px;
    padding: 0;
    width: 170px;
  }

  .d_find_event .d_product_img img {
    border-radius: 50% !important;
    border: 4px solid #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
  }

  .d_find_event .d_product_title {
    margin-top: 9px;
  }

  .d_hangout_box .d_product_img {
    height: 120px;
    /* padding: 0px 5px 5px 5px; */
  }

  .d_hangout_box .d_product_title {
    color: #221e20;
  }
  .d_event_detl .d_comn_btn {
    margin-left: 34px;
    max-width: 172px;
  }
  .d_event_expert {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .d_left_panal {
    min-height: 817px;
    /* max-height: auto; */
  }
  .d_beer_product_cnt_title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 7px;
  }

  .d_beer_product {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
    border-radius: 5px;
  }

  .d_beer_product_cnt_rats strong {
    color: #2f2f2f;
  }

  .d_beer_product_cnt_link {
    color: #72b625;
  }

  .d_beer_product_img {
    position: relative;
  }

  .d_beer_product_img figure {
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    overflow: hidden;
    height: 134px;
  }

  .d_beer_product_img figure img {
    height: 100%;
    object-fit: cover;
  }
  .d_add_new_product .d_beer_product_cnt_title {
    font-size: 16px !important;
    margin-bottom: 23px;
  }
  .d_order_summry .box_padding {
    padding: 15px 20px;
  }

  .coupon_panel .d_order_summry .box_padding {
    padding: 13px;
  }

  .d_order_summry .d_textarea {
    height: 65px;
    font-size: 13px;
    color: #757575 !important;
    letter-spacing: 0.01em;
  }

  .d_card_icon_count {
    width: 20px;
    height: 20px;
    background-color: #6fac2f;
    bottom: -7px;
    color: #fff;
    border: 1px solid #fff;
    font-size: 14px;
  }

  .d_card_icon {
    margin-right: 15px;
  }

  .coupon_panel .d_card_icon img {
    border: 1px solid #d1d1d1;
    border-radius: 5px;
  }

  .coupon_panel .d_card_icon {
    width: 45px;
    height: 45px;
  }

  .d_order_border_btm {
    border-bottom: 1px solid #dadada;
  }
  .d_order_border_btm2 {
    border-top: 1px solid #dadada;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  }
  .d_order_list_name li img {
    margin-right: 10px;
  }

  .d_order_list_name li .p_gray {
    font-size: 13px !important;
  }

  .d_order_list_name .vage_curcul {
    top: 0;
    right: auto;
  }

  .d_order_list_name .vage_curcul {
    top: 0;
    right: auto;
    margin-right: 7px;
  }

  .d_order_list_name li {
    display: inline-block;
    vertical-align: top;
  }

  .d_order_list_name li p {
    font-size: 14px;
    line-height: 21px;
    color: #505050;
  }

  .d_order_list_name li p span {
    font-family: "Averia";
    color: #222222;
    font-weight: 700;
  }

  .vage_curcul {
    background: #ffffff;
    border: 1px solid #21d052;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 11px;
    right: -9px;
  }

  .vage_curcul em {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #21d052;
    border-radius: 50%;
  }

  .g-add .form {
    width: 100%;
    padding-top: 0;
    display: flex;
    border: 1px solid #dddddd;
    border-radius: 3px;
  }

  .g-add .value-button {
    margin: 0px;
    width: 40px;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #000;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .g-add input.number {
    text-align: center;
    border: none;
    margin: 0px;
    width: 100%;
    vertical-align: middle;
    color: #000;
    padding: 4px 0;
  }

  .g-add .value-button:hover {
    cursor: pointer;
  }

  .g-add form #decrease {
    margin-right: -4px;
    border-radius: 8px 0 0 8px;
  }

  .g-add form #increase {
    margin-left: -4px;
    border-radius: 0 8px 8px 0;
  }

  .g-add form #input-wrap {
    margin: 0px;
    padding: 0px;
  }

  .delete {
    margin-left: 11px;
    color: #d43838;
    font-size: 18px;
  }

  .d_event_list {
    column-count: 2;
    margin-top: 12px;
  }

  .form_check_box label {
    color: #000;
    font-size: 14px;
  }

  .d_event_list .form_check_box_label .checkmark {
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #afafaf;
  }

  .d_event_list .form_check_box_label .checkmark:after {
    left: 4px;
    top: 0px;
    width: 6px;
    height: 11px;
  }

  .d_event_list .form_check_box label {
    color: #353535;
    font-size: 14px;
  }

  .form_check_box_label {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .form_check_box_label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .form_check_box_label .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #d6d6d6;
    border-radius: 2px;
  }

  .form_check_box_label:hover input ~ .checkmark {
    background-color: #d6d6d6;
  }

  .form_check_box_label input:checked ~ .checkmark {
    background-color: #6fac2f;
  }

  .form_check_box_label .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .form_check_box_label input:checked ~ .checkmark:after {
    display: block;
  }

  .form_check_box_label .checkmark:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .form_check_box label {
    color: #000;
    font-size: 14px;
  }

  .checkbox_link {
    color: #1ca3ef;
    text-decoration: underline !important;
    display: inline-block;
  }

  .d_paid {
    font-size: 15px;
    font-weight: 400;
    vertical-align: top;
    color: #000;
  }
  /* login part */

  /* */

  .login_page .d_create_event {
    background-color: white;
    padding: 44px 40px;
  }
  .foot-login {
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    border-top: 1px solid #888888;

    padding: 3%;
  }

  .mug_club .card {
    margin: 2%;
  }
  #mugClub-form input {
    background-color: #8888880d;
    outline: none;
  }
  #mugClub-form textarea {
    background-color: #8888880d;
  }
  .LoginForm_form__TYSXP .poppins {
    padding-left: 70px;
  }
  .img_dimen {
    color: #86ab43;
    font-size: 15px;
    margin-top: 5px;
  }

  @media only screen and (min-width: 900px) {
    .login_page input {
      border: none;
      outline: none;
      width: 80%;
      /* width: 414px; */
      padding-left: 10px;
      /* height: 40px; */
    }
  }
  /* contact us  */
  .login_page .form-group {
    border: 1px solid #63636365;
    border-radius: 5px;
    padding: 1%;
    width: 300px;
    height: 36px;
    margin: 1px auto;
  }


  @media only screen and (min-width: 900px) {
    .d_locat_boxx {
      width: 94%;
      margin: 0px auto;
    }

    #price-on-pic {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {

    .addOnItems_block {
      padding-left: 3% !important;
      padding-top: 3% !important;
  }
#btn_Check{
      width: 100px !important;
    height: 40px !important;
    margin: 0px auto !important;
    margin-top: 10px !important;
    }
    body {
      background-color: white;
    }

    .form-group input {
      /* float: right;
    width: 630px; */
      outline: none;
      border: none;
    }

    .form-group {
      /* border-bottom: 1px solid black; */
      margin-top: 2%;
      padding-bottom: 2%;
    }

    .form-group img {
      position: relative;
      /* margin-bottom: -15px; */
      width: 23px;
    }


    #footer-desktop {
      display: none;
    }


    .login_page .d_create_event {
      width: 310px;
      margin: 0;
    }

    .form-group input {
      float: none;
      width: auto;
      outline: none;
      border: none;
      margin: 3%;
    }

    .login_page .d_main_panel {
      padding: 50px 0;
    }

    /* event save page in mobile view */
    .d_create_event {
      width: 100%;
    }

    .back-button-header {
      padding: 2%;

      height: 70px;
      width: 100%;
      border-bottom: 3px solid rgba(146, 145, 145, 0.679);
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;
    }

    /* event list page for mobile view */

    .d_feat_event_list li {
      display: inline-block;
      width: 100%;

      margin-left: -35px;
      font-size: 14px;
      color: #636363;
    }

    .d_feat_event_cnt {
      padding: 7px 4px 0px 5px;
    }
    .d_reminder {
      display: none;
    }

    #price-on-pic {
      float: right;
      position: relative;
      top: -20px;
      background-color: white;
      border-radius: 25px;
      padding: 2%;
    }

    .d_feat_event_name {
      top: -20px;
      position: relative;
    }
    .d_feat_event_list {
      position: relative;
      top: -32px;
    }
    .d_book_event {
      background-color: #9ac0596d;

      width: 130px;
      border-radius: 7px;
      color: white;
    }

    .d_feat_event_btn {
      position: relative;
      margin-top: -50px;
      /* height: 40px; */
      padding-left: 5%;
      border-top: 0px solid #dbe3e7;
    }
    .ph-none {
      display: none;
    }
    .d_main_panel {
      padding: 4px 0;
    }
  }

  @media screen and (min-width: 800px) {
    .back-button-header {
      display: none;
    }
  }

  /*  Manoj */
  .view-all-events {
    float: right;
    font-size: 15px;
  }
  a,
  a:hover {
    text-decoration: none;
    color: #636363;
  }

  p.errorMsg {
    margin: 0.25rem 0 0 0;
    color: #e11d48;
    font-weight: 400;
    font-size: 18px;
    font-family: "Averia";
  }
  .pull-left {
    float: left;
  }
  .pull-right {
    float: right;
  }
  .auto_w_comn_btn {
    cursor: pointer;
    padding: 5px;
    display: inline-block;
    text-align: center;
    background-color: #70ac2fa7;
    text-transform: uppercase;
    color: #fff;
    font-size: 17px;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    border: 0;
    width: auto;
  }
  .disabled-link {
    pointer-events: none;
  }

  
  
  /* chnages 23/10/22 */

  .profile-page {
    margin: 1.2rem auto;
    width: 40%;
  }

  .control-input {
    width: 100%;
    padding: 1.3%;
    border-radius: 5px;
    border-color: rgba(85, 85, 85, 0.16);
  }

  .profile-form {
    padding: 2px 10%;
  }

  #profile {
    margin: 3%;
    margin-left: 15%;
  }
}

/* my-profile page css 11/11/22 */

#user_pro_pic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  padding: 2%;
}

#profile-page-design {
  width: 400px;
}

#profile-page-design input {
  margin-bottom: 2%;
}
#profile-page-design label {
  font-size: 13px;
}

/* end my-profile page css */

/* hover cicrle effect  */

.adj-location img {
  /* width: 65%; */
  width: 140px;
  border-radius: 50%;
}

.adj-location img:hover {
  border: 3px solid #a2c760;
}

/* End hover cicrle effect  */

/* whatHappingWeek  manoj 23-11-2022  */
.whatHappingWeek .mb-4,
.whatHappingWeek .mb-3 {
  margin-bottom: 0.5em !important;
}
.whatHappingWeek .d_days_title {
  margin-bottom: 5px !important;
}

.d_days_subtitle {
  font-size: 100%;
}

/* 17 november 2022 manoj */
#imageCropModal .modal-header i {
  color: red;
  cursor: pointer;
}
#imageCropModal .modal-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#imageCropModal .modal-body > div:nth-child(1) {
  height: 300px;
}
#imageCropModal .modal-body > div:nth-child(2) {
  top: 180px;
  position: relative;
  background-color: #fff;
  padding: 20px;
}
#imageCropModal .reactEasyCrop_CropArea {
  top: 45% !important;
}

/* for screen more than 1312px */

@media only screen and (min-width: 1312px) {
  /* home page doolally section height */
  #hm-whatsOnatp {
    min-height: 900px;
  }

  #hm-hangout {
    min-height: 590px;
  }

  #hm-whatshappening {
    height: 510px;
  }
}
#event-list-details{
  padding-left: 3%;
}
@media only screen and (max-width: 1300px) {
  #event-list-details{
    padding-left: 0%;
  }
 
}
@media only screen and (max-width: 1300px) {


  #hm-hangout {
    min-height: 626px;
  }
  #hm-whatshappening {
    height: 510px;
  }
}
/* 28/11/22 */

.d_book_event:hover {
  background-color: #a2c760;
  color: white;
}

.d_comn_btn:hover {
  background-color: #65a126;
  color: white;
}

.modal-90w {
  width: 100%;
  max-width: none !important;

  bottom: -5%;
  position: fixed;
  top: auto;
  right: auto;
  left: auto;
}

.z {
  padding: calc(var(--bs-modal-header-padding-y) * 0.1)
    calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * -0.5)
    calc(var(--bs-modal-header-padding-y) * 0.5) auto;
  margin-right: 0% !important;
  border: none;
  outline: none;
}
#circle-effect img {
  border: 3px solid #a2c760;
}
.attaind-pg-btn {
  margin: 0px auto;
}
.attaind-pg-btn button {
  padding: 2%;
  width: 12rem;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #a2c760;
}
.attaind-pg-btn button:hover {
  background-color: #a2c760;
}

.btn-deco {
  border: none;
  outline: none;
  background-color: #a2c760;
  color: white;
  padding: 1% 2% 1% 2%;
  border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.wrapperr {
  /* height: 10%; */
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 12px;
  border: 2px solid#ced4da;
}

.wrapperr span {
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}

.wrapperr span.num {
  font-size: 20px;
  border-right: 2px solid rgba(0, 0, 0, 0.2);

  border-left: 2px solid rgba(0, 0, 0, 0.2);
}
#my-event-btn {
  margin-right: 0% !important;
}

#camera {
  font-size: 5rem;
  color: #a2c760;
  padding: 2%;
}
.cancel-pg-btn {
  margin: 0px auto;
}
.cancel-pg-btn button {
  padding: 2%;
  width: 6rem;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #a2c760;
}
.cancel-pg-btn button:hover {
  background-color: #a2c760;
}

.my-event-img{
  width: 8rem;
  height:5rem;
  margin-left:10%;
  
  
}

.editCreatedEvent{
  margin-top: -0.0000001px;
}

.link:hover {
   color: white
}


.myEventDetails .text-center {
  text-align: center;
}
.myEventDetails .mdl-grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  align-items: stretch;
  cursor: pointer;
  padding: 8px;
}
.myEventDetails .eventDash-stats {
  width: calc(100% - 0px);
}
.myEventDetails .eventDash-stats .list-inline {
  list-style: none;
  padding: 0;
}
.myEventDetails ul,
ol {
  font-size: 14px;
  line-height: 24px;
}
.myEventDetails .eventDash-stats .list-inline li:nth-child(2n + 1) {
  background: #fffefc;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 2px;
}
.myEventDetails .eventDash-stats .list-inline > li {
  display: inline-block;
  vertical-align: top;
}
.myEventDetails .dashboard-stats {
  margin: 10px 0;
  font-family: "Averia";
  font-weight: bold;
}
.myEventDetails .dashboard-stats h4 {
  font-size: 24px;
  line-height: 32px;
}
.myEventDetails .dash-spacer {
  margin-left: 40px;
  margin-right: 40px;
}
.myEventDetails .host-event-segregation,
.myEventDetails .host-signup-list {
  transition: all 0.5s ease;
  background: #ececec;
  padding-bottom: 10px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}
.myEventDetails .common-head-title {
  padding: 0 10px;
  width: auto;
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #000;
}
.myEventDetails .mdl-shadow--2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.myEventDetails .d_feat_event_cnt {
  padding: 17px 17px 0px 17px;
  height: auto;
}
.myEventDetails .host-event-segregation .custom-host-card {
  background: #fff;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 2px;
}
.myEventDetails .mdl-card__supporting-text {
  width: auto;
  padding: 0 15px;
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  line-height: 18px;
  overflow: hidden;
}
.myEventDetails
  .host-event-segregation
  .custom-host-card
  .mdl-card__supporting-text
  .demo-list-icon {
  margin: 0;
  padding: 0;
}
.myEventDetails .mdl-list {
  padding: 8px 0;
  list-style: none;
  background-color: #fff;
}
.myEventDetails .mdl-list__item {
  font-family: "Averia";
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1;
  min-height: 48px;
  padding: 16px;
  cursor: default;
  color: rgba(0, 0, 0, 0.87);
  overflow: hidden;
}
.myEventDetails .host-event-segregation .custom-host-card .cost-heading {
  opacity: 0.5;
}


.mdl-list__item .mdl-list__item-primary-content {
  order: 0;
  flex-grow: 2;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  float: left;
}
.dim-text-opacity {
  opacity: 0.5;
}
.mdl-list__item-avatar {
  height: 25px;
  width: 25px;
  font-size: 20px;
  margin-left: 10px;
  color: #a2c760;
  border-radius: 50%;
  border: 1px solid #a2c760;
}
.mdl-list__item-avatar .mdl-chip__text {
  position: relative;
  top: 0px;
  left: 2px;
  font-size: 13px;
}
.mdl-list__item-secondary-action {
  font-size: 12px;
  float: right;
}
.ic_event_email_icon {
  background: url(../images/ic_event_email.png) no-repeat;
  background-size: auto;
  display: block;
  width: 30px;
  height: 20px;
  background-size: 100% 100%;
  cursor: pointer;
}

.container.gif {
  text-align: center;
  padding-top: 145px;
  background-color: #e0e0e094;
  position: absolute;
  overflow: hidden;
  z-index: 9;
  height: 1000px;
  width: 100%;
  max-width: -webkit-fill-available;
  left: 0px;
}

.hideDiv {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.626);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#my-event-details{
  padding-left: 2.4%;
  margin-top: 0px;

}

#my-event-details p{
  font-size: 2.6rem;
}

input[type=number] {
  -moz-appearance: textfield;
}

#more-information{
  color: #a2c760;
  padding-top: -115%;
  }
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.1rem;
    margin: 2px 5px 6px;
}

button.remove-image{
  background-color: #ffffff;
  outline: none;
  border: none;
}

.container1 {
  margin: 20px auto;
  max-width: 640px;
}

img {
  max-width: 100%;
}


/* The css styles for `outline` do not follow `border-radius` on iOS/Safari (#979). */
.cropper-view-box {
  outline: 0;
  box-shadow: 0 0 0 1px #39f;
}
.font-contact-org{
  font-family: Averia !important;
}
span {
  font-family: "Averia" !important;
}

.paggination-btn button{
  background-color: #a2c760;
  color: #ffffff;
  border: none;
  padding: 0.5% 2% 0.5% 2%;
  margin: 1%;
float: right;
border-radius: 5px;
}
.paggination-btn p{
 padding-top:  13px;
 color: #a2c760;
}

.card-order_details{
  
  /* height:300px; */
  border: 1px solid #69696910; 
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transform: scale(0.8);
  padding: 3%;
}
.card-order_details img{
  border-radius: 8px;
  width: 100px;
  height: 100px;
}
.card-order_details p{
margin-top: 4%;
color: #000000a0;
}

#deliver-status{
  padding: 10px 30px 10px 30px;
  background-color: rgba(75, 181, 67, 0.188);
  border-radius: 25px;
  color:#4BB543;

}

.dt-price{
  /* width:100%; */
  background-color: rgba(224, 219, 219, 0.53);
  padding: 15px 20px 15px 20px;
border-radius: 15px;
display: flex;
justify-content: space-between;
font-weight: 800;
}
#order-items{
  font-weight: 900;
  font-size: 18px;
  height:30px
}

.my-divider {
  width: 100%;
  height: 1px;
  margin: 8px 0;
  border: none;
  background-color: rgba(0, 0, 0, 0.12);
}
.btn-reorder span{
  width: 150px;
  padding: 2px 10px 2px 55px;
  color: #a2c760;
  border: 2px solid #a2c760;
  border-radius: 25px;
  font-size: 17px;
}
.btn-reorder span:hover{
color:#ffffff;
background-color: #a2c760;
}


.cart-container {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.cart-container:hover {
  transform: scale(1.05);
}

.cart-container img {
  width: 20px;
  margin-right: 10px;
}

.cart-container #cart-qty-indicate {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

#nearest-btn {
  padding: 3% 8% 3% 8% !important; 
}

.d_location {
  background-color: #a2c760c9;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 3px;
}

.scroll-parent {
  /* position: relative; */
  /* width: 100vw; */
  height: 20rem;
  overflow-x: hidden;
}

.scroll-element {
  /* width: inherit; */
  /* height: inherit; */
  /* position: absolute; */
  left: 0%;
  top: 0%;
  animation: primary 3s linear infinite;
}

@keyframes primary {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}

.pincode {
  background-color: #a2c760;
    border-radius: 14px;
    display: block;
    width: 112px;
    height: 50px;
    margin-left: 177px;
    margin-top: 5px;
}

.quantity-container2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5; /* Same background as the "Add" button */
  border-radius: 5px; /* Rounded corners */

  padding: 0 5% 0 5%;
}

.quantity-btn {
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  color: #6c757d; /* Same color as the text in the "Add" button */
  cursor: pointer;
}

.quantity-input {
  width: 30%;
  text-align: center;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  color: #6c757d;
}

.quantity-container2:hover {
  background-color:  #edf7dd!important ;
  color: white;
}

/* Base Container */
/* .pickupOption {
  font-family: "Arial", sans-serif;
} */

.pickupOption h4 {
  font-size: 1.5rem;
}

/* Service Option Buttons */
.pickupOption .btn-primary-custom {
  background-color: #a2c760;
  color: white;
  border: none;
  font-weight: 500;
}

.pickupOption .btn-primary-custom.active-option {
  box-shadow: 0 4px 8px rgba(162, 199, 96, 0.4);
}

.pickupOption .btn-outline-primary {
  border: 2px solid #a2c760;
  color: #a2c760;
  font-weight: 500;
}

.pickupOption .btn-outline-primary:hover {
  background-color: #a2c760;
  color: white;
  transition: all 0.3s ease-in-out;
}

/* Pickup Confirmation Section */
.pickupOption .pickup {
  background-color: #f0f8e0;
  color: #333;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.pickupOption .pickup h5 {
  color: #5a7435;
  font-size: 1.25rem;
}

.pickupOption p {
  font-size: 0.95rem;
  margin-bottom: 0.75rem;
}

.pickupOption strong {
  font-weight: 600;
}

/* Hover Effect */
.pickupOption .btn {
  transition: all 0.3s ease;
}
