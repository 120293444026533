.order-history-card {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    padding: 20px;
  }
  .delivery-status {
/* background-color: #a2c760; */
background-color: rgba(162, 199, 96, 0.5);
backdrop-filter: blur(10px);
border-radius: 10px;
box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);

  }


  .order-user-detail span{
    line-height: 2px;
    color: rgba(69, 67, 67, 0.304);

  }
#inputNearser{
  padding: 1%;
    border: 1px solid grey;
    display: flex;
    margin: 0 auto;
    border-radius: 10px;
}
