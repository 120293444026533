.form {
  max-width: 30rem;
  margin: auto;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.formLabel {
  margin-bottom: 0.25rem;
}

.formField {
  padding: 0.5rem;
}

.formFieldError {
  border: 1px solid #e11d48;
}
.poppins{
  padding-left: 70px;
}
.formFieldErrorMessage {
  display: inline-block;
  margin: 0.25rem 0 0 35px;
  color: #e11d48;
  font-size: 15px;
}

.formActions {
  display: flex;
  justify-content: flex-end;
}

.formSubmitBtn {
  padding: 0.5rem 0.7rem;
  min-width: 5rem;
  background-color: #9333ea;
  color: #f3e8ff;
  text-transform: uppercase;
  font-weight: 500;
}
