p {
  font-size: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}
#crtfood p{
  margin-bottom: 4px !important;
}
.d_book_event {
  background-color: #edf7dd;
  opacity: 90%;
  outline: none;
  border: none;
  width: 115px;
  margin-right: 4%;
  border-radius: 4px;
  color: #759246;
}
.d_linee {
  width: 100px;
  height: 3.5px;
  background-color: #9bc059;
  display: inline-block;
  vertical-align: middle;
}

/* foter mob  */

.menu-footer-mob ul li{
  display: block;
  height: 45px;
 font-weight: 900;

}
.menu-footer-mob ul li:hover{
color:#a2c760
 

}
.error23{
  line-height: 0;
  text-align: center;
  font-size: 15px;
  color: #e11d48;;
  letter-spacing: 0.2px;
}
#loadgif{
  display: block;
  margin: 0 auto;
  padding: 2%;
  width: 100px;
}
.contact_us_eror{
  color: #f20707;
    font-size: 14px;
    text-align: end;
    margin-left: 19%;
}
@media only screen and (max-width: 900px) {

  .menu_food_bar{
    position: sticky;
    z-index: 99;
    top : 71px;
  }
  .menu_food_category{
    position: sticky;
    z-index: 99;
    top : 150px;
    background-color: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  }

  .contact_us_eror{
      margin-left: 12%;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .addOnItems_block {
    padding-left: 3% !important;
    padding-top: 3% !important;
}
.contactImg img {
  width: 20px;
  height: 20px;
  margin: 10px 10px 0 0!important;
}
  .coupon-code {
    display: flex; /* Makes input and button appear on same line */
  }
  
  .coupon-code input {
    padding: 10px; /* Adds some padding to the input */
    border: 1px solid #ccc; /* Adds a border to the input */
    border-radius: 4px; /* Adds some rounded corners to the input */
    flex: 1; /* Makes input take up remaining space */
  }
  
  .coupon-code button {
    padding: 10px 20px; /* Adds some padding to the button */
    background-color: #a2c760; /* Sets the background color of the button */
    color: #fff; /* Sets the text color of the button */
    border: none; /* Removes the button border */
    border-radius: 4px; /* Adds some rounded corners to the button */
    margin-left: 10px; /* Adds some space between input and button */
    cursor: pointer; /* Changes the mouse cursor when hovering over the button */
  }
  

.ph-n{
  display: none;
}
#file-uplod-img{
  width: 100% !important;
  height: 289px;
}

  @font-face {
    font-family: "MyFont";
    src: url("../fonts/Dirty\ Headline.ttf");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: "Averia";
    src: url("../fonts/AveriaSerifLibre-Regular.ttf");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }
  @font-face {
    font-family: Averia;
    src: url("../fonts/AveriaSerifLibre-Regular.ttf");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }
  .delivryAddress{
    border: 1px solid rgba(128, 128, 128, 0.614);
    border-radius: 4px;
    padding: 3%;
  }

.cart-form {
  display: flex;
  flex-direction: column;
  /* width: 400px; */
  margin: 0 auto;
}

#deliver-status{
  font-size: 14px;
  padding: 5px 21px 5px 18px;
  
}
.card-order_details {
  
  transform: scale(0.9);
  
}
label {
  font-weight: bold;
  margin-bottom: 8px;
}

input[type="text"],
input[type="select"],
input[type="email"],
input[type="tel"],
textarea {
  border: 1px solid rgba(128, 128, 128, 0.614);
  border-radius: 4px;
  padding: 7px;

  font-size: 16px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

/* input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
} */

button[type="submit"] {
  background-color: #a2c760;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

button[type="submit"]:hover {
  background-color: #a1c760c2;
}
#pay-mode-mob{
  padding-left: 23px !important;
  font-size: 18px !important;
}
  p.errorMsg {
  
  color: red;
  margin: 2%;
  
  }

  body {
    margin-top: 70px;
    /* font-family: 'Averia Serif Libre cursive';  */
    font-family: "Averia";
    background-color: #f2f2f2;
    width: auto !important;
    overflow-x: hidden !important;
  }

.box-org-dasboard{
  border: 2px solid rgba(0, 0, 0, 0.332);
  padding: 2%;
  border-radius: 5px;
  margin:4% 0 4% 0;

}
.box-org-dasboard img{
  height: 100px;
  width:120px
}

.box-org-dasboard h6{
  font-size: 16px;
  color: rgba(0, 0, 0, 0.574);
}
.box-org-dasboard span{
  font-size: 17px;
  color: black;
}
.box-org-dasboard button{
  margin-left: 35%;
  padding: 0 2% 0 2%;
  border-radius: 5px;
  background-color: #a2c760;
  color: #ffffff;
  border:none
}

  #table_my_event th{
width: 500px !important;
  }

  .scroll-orgniser-dashboard{
    overflow: scroll;
  }
  .scroll-orgniser-dashboard::-webkit-scrollbar {
    display: none;
  }
  /* for font */
  .attaind-pg-btn button {
    width: 100%;
    padding: 2%;
  }
  .attaind-pg-btn {
    margin-top: -9%;
    /* margin-left: 2%; */
    padding-bottom: 4%;
  }

  #Averia_font {
    font-family: "Averia";
  }
  #dirty_hedline_font {
    /* font-family: 'Dirty Headline'; */
    /* font-family: 'Dirty Headline', sans-serif; */

    font-family: "MyFont";
  }

  ul.d_event_list {
    padding-left: 0rem;
  }

  #share-list {
    width: 200px;
    /* background-color: rgba(255, 255, 255, 0.685); */
    position: relative;
    /* background-color: red; */
    overflow: hidden;
  }

  #testimonial-desktop {
    margin: 2% 3% auto;
    background-color: #f1f1f1;
  }
  .review_pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    margin-top: -10px;
    position: relative;
    z-index: -2;
    /* border: 1px solid white; */
    /* margin: 2%; */
    border: solid 3px white;

    /* display:contents; */
  }
  .review_mobile_view {
    /* margin-left: 2%;
    margin-right: 2%; */
    border-radius: 7px;
    /* width:220px; */
    width: 95%;
    /* height:14.4rem; */
    background-color: #f1f1f1;
    color: #5b5b5b;
    border: none;
    outline: none;
    opacity: 80%;
  }
  /*  for entry 18 */

  #entry-gate {
    /* margin: 220px auto; */
    /* width: 300px;
    height: 200px; */
    /* box-shadow: 2px 5px 3px #888888; */
    background-color: white;
    /* padding: 1%; */
    text-align: center;
  }

  #btn-18 {
    width: 100px;
    /* padding: 2% 12% 2% 12%; */
    height: 34px;
    outline: none;
    border: 1px solid #cdc8c8;
    background-color: #fff;
    color: black;
    text-transform: none;
    font-family: "Averia" !important;
    text-align: center;
    margin: 0px 12px auto;
    border-radius: 5px;
  }
  #btn-18:hover {
    background-color: #a2c760;
    color: #fff;
  }
  /* for home page */

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  /* for Header */
  .card img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    float: right;
  }
  .card-text {
    float: left;
    top: 400px;
    color: #5b5b5b;
  }

  .h-main {
    width: 100%;
    top: 0;
    background-color: white;
    position: sticky;
    z-index: 1;
    padding: 0.9%;
    height: 70px;
    box-shadow: 1px 1px 5px #c0c0c0b9;
    /* display:; */
    position: fixed;
  }
  .h-main img {
    cursor: pointer;
  }

  .h-left img {
    width: 30px;
    /* padding: 2%; */
  }
  .h-center {
    text-align: center;
  }
  .h-right img {
    width: 20px;
    padding-right: 5px;
  }
  .h-right {
    text-align: end;
    padding-top: 8px;
  }
  .h-left {
    padding-top: 8px;
  }

  /* searchBar */

  .s-main {
    padding: 1%;
    background-color: white;
    justify-content: center;
    text-align: center;
  }
  .serachbar {
    display: flex;
  }

  .serachbar {
    padding: 1px;
    background: #a2c760;
    border-radius: 2px;
    position: relative;
  }
  .serachbar input::placeholder,
  .filter-box input::placeholder {
    font-size: 14px;
  }

  .serachbar button {
    border: none;
    position: absolute;
    right: 5px;
    background: transparent;
    top: 7px;
  }

  .serachbar button img {
    width: 19px;
  }

  /* main section */

  .Main {
    /* background-color: #A2C760; */
    height: 800px;
  }

  .box_padding {
    padding: 20px;
  }

  .d_product_box {
    background: #fff;

    box-shadow: 0px 1px 6px rgb(0 0 0 / 15%);

    border-radius: 5px;

    /* overflow: hidden; */

    position: relative;
  }

  .d_product_img img,
  .d_days_iner_box figure img,
  .d_featured_events_box figure img,
  .d_event_sidepanel figure img,
  .d_card_icon img {
    width: 100%;

    height: 100%;

    object-fit: cover;
  }

  .d_product_img {
    width: 100%;

    height: 126px;
  }

  .d_main_panel {
    padding: 40px 0;

    /* min-height: 79vh; */
  }
  .d_main_panel_review {
    padding-top: 15px;
    /* min-height: 100vh; */
  }

  .d_hangout {
    margin-top: 26px;
    min-height: 77vh;
  }
  .gray_bg_color {
    /* background: #F2F2F2; */
    background: white;

    box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);

    border-radius: 5px;
  }

  .d_title_box {
    margin-bottom: 24px;
  }
  .d_main_title {
    font-weight: 600;

    font-size: 18px;

    line-height: 20px;

    color: #221e20;

    margin-bottom: 4px;
  }

  .d_main_sub_title {
    font-weight: 400;

    font-size: 16px;

    line-height: 20px;

    color: #a2c760;
  }

  .d_line,
  .d_round {
    background-color: #9bc059;

    display: inline-block;

    vertical-align: middle;
  }

  .d_line {
    width: 40px;

    height: 2.4px;
  }

  .d_round {
    width: 2.4px;

    height: 2.4px;

    border-radius: 50%;

    margin-left: 3px;
  }

  .d_product_title {
    font-weight: 400;

    font-size: 14px;

    line-height: 21px;

    text-align: center;

    color: #5c5c5c;

    padding: 10px 0;

    /* font-family: 'Poppins', sans-serif; */
  }

  .d_locat_name,
  .d_cnt_locat,
  .d_feat_event_title,
  .d_product_title,
  .d_overlay p,
  .d_days_title,
  .d_feat_event_btn a {
    letter-spacing: 0.01em;
  }

  .d_locat_name {
    font-size: 14px;
    line-height: 21px;
    color: #222222;
    margin: 4px 0;
  }

  .d_days_title {
    font-size: 18px;
    line-height: 22px;
    color: #221e20;
    margin-bottom: 10px;
  }

  .d_product_title {
    font-weight: 400;

    font-size: 14px;

    line-height: 21px;

    text-align: center;

    color: #5c5c5c;

    padding: 10px 0;

    /* font-family: 'Poppins', sans-serif; */
  }

  .d_overlay {
    position: absolute;

    left: 0;

    right: 0;

    top: 0;

    bottom: 0;

    overflow: hidden;

    width: 100%;

    height: 100%;

    transition: 0.5s ease;

    background: rgba(162, 199, 96, 0.95);

    box-shadow: 0px 1px 6px rgb(0 0 0 / 15%);

    border-radius: 5px;

    display: flex;

    align-items: center;

    justify-content: center;

    opacity: 0;
  }

  .d_overlay p {
    font-weight: 700;

    font-size: 13px;

    line-height: 16px;

    text-align: center;

    text-transform: uppercase;

    color: #fff;
  }

  .d_product_box:hover .d_overlay {
    opacity: 1;
  }

  .d_right_panal {
    background: #fff;

    box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);

    border-radius: 5px;
  }

  .d_days_iner_box {
    display: flex;
  }

  .d_days_iner_box figure {
    width: 90px;

    height: 60px;

    margin-right: 12px;
  }

  .d_days_iner_box figure img {
    border: 1px solid #d8d8d8;

    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.15));

    border-radius: 5px;
  }

  .d_right_panal {
    background: #fff;

    box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);

    border-radius: 5px;
  }

  .d_locat_box {
    background: #fffcf1;
    border: 1px solid #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
    width: 180px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    overflow: hidden;
    margin: auto;
    position: relative;
  }

  .d_locat_cnt img {
    display: inline-block !important;
  }

  .location-section {
    /* width: 90%; */
    /* border: 15px solid green; */
    /* padding: 50px; */
    margin: 0 2% 5% 2%;
    padding-bottom: 2%;
  }

  .footer {
    /* position: fixed; */
    padding: 3%;
    left: 0;
    bottom: 0;
    width: 100%;
    /* height: auto; */
    background-color: white;
    color: black;
    /* text-align: center; */
    /* margin: auto; */
    /* border-bottom-style: solid;
    border-bottom-width: medium; */
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  #end-footer {
    border-top-style: ridge;
    color: rgb(11, 11, 11);
    background-color: white;
    width: 100%;
  }

  /* evnt details */

  .black_heading .d_main_sub_title {
    font-size: 24px !important;
    line-height: 29px !important;
    color: #221e20 !important;
  }

  .black_heading .d_line,
  .black_heading .d_round {
    background-color: #221e20 !important;
  }

  .d_feat_event_cnt {
    padding: 17px 17px 0px 17px;
  }

  /* .poppins {
    font-family: 'Poppins', sans-serif !important;
} */

  .d_feat_event_title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .d_feat_event_title {
    font-weight: 500;
    font-size: 17px;
    line-height: 27px;
    color: #221e20;
    margin-bottom: 4px;
  }

  .d_feat_event_name {
    font-size: 16px;
    color: #678a29;
  }

  .d_art_craft {
    margin: 26px 0;
  }

  .d_art_craft span {
    box-shadow: 0px 0px 4px #6fac2f;
    border-radius: 5px;
    background-color: #fff;
    padding: 11px 20px;
    display: inline-block;
    margin-right: 18px;
    font-size: 15px;
  }

  .d_art_craft span img {
    vertical-align: text-top;
    margin-right: 4px;
  }

  .d_art_craft p {
    line-height: 27px;
  }

  .p_gray {
    color: #636363 !important;
    font-size: 14px;
  }

  .d_title_box .p_gray {
    margin-top: 7px;
  }

  .hangout_dtl .p_gray {
    line-height: 26.62px;
  }

  .d_comn_btn:hover {
    background-color: #a2c760;
    color: #fff;
  }

  .d_comn_btn {
    padding: 12px 30px;
    display: inline-block;
    text-align: center;
    background-color: #a2c760;
    /* text-transform: uppercase; */
    color: #fff;
    font-size: 17px;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    border: 0;
  }

  .d_private_party .d_comn_btn {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    max-width: 215px;
    margin: 0 auto;
    padding: 12px 0;
  }

  .event_dtl_footer_list li {
    display: inline-block;
    text-align: center;
    margin-left: 20px;
  }

  .event_dtl_footer {
    border-top: 1px solid #d4d4d4;
    border-bottom: 1px solid #d4d4d4;
    background-color: #fff;
    padding: 10px 16px;
  }

  .event_dtl_footer_cnt p {
    color: #221e20;
    font-size: 15px;
  }

  .event_dtl_footer_list li a {
    color: #999999 !important;
    font-size: 13px;
  }

  .event_dtl_footer_list li .fa {
    font-size: 17px;
    vertical-align: bottom;
  }

  .event_dtl_footer_list {
    margin: 0;
  }
  .d_event_sidepanel figure img,
  .d_card_icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .d_event_sidepanel {
    box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }

  .d_event_sidepanel figure {
    width: 100%;
  }

  .d_btn {
    padding: 15px 0;
    display: block;
    text-align: center;
    background-color: #a2c760;
    text-transform: uppercase;
    color: #fff;
    font-size: 17px;
    transition: all 0.3s ease-in-out;
  }

  .d_btn:hover,
  .d_comn_btn:hover {
    background-color: #a2c760;
    color: #fff;
  }

  /* event list */

  .d_find_event {
    margin-bottom: 25px;
  }

  .d_find_event .d_main_sub_title {
    font-size: 24px;
    line-height: 29px;
    color: #221e20;
  }

  .d_find_event .d_line,
  .d_find_event .d_round {
    background-color: #221e20;
  }

  .d_featured_events_box figure img {
    /* filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.15)); */
    border-radius: 5px 5px 0px 0px;
  }

  .d_featured_events_box {
    box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);
    border-radius: 5px;
  }

  .d_feat_event_list li {
    display: inline-block;
    width: 49%;
    /* padding: 0px 0 0 0; */
    font-size: 14px;
    color: #636363;
  }

  .d_feat_event_list li img {
    width: 23px;
  }

  .d_feat_event_btn {
    border-top: 1px solid #dbe3e7;
    display: flex;
    align-items: center;
  }

  .d_feat_event_btn a {
    width: 50%;
    padding: 13px 0;
    text-align: center;
    font-size: 14px;
  }



  /* Event Save */

  .d_create_event {
    width: 700px;
    margin: 0 auto;
  }

  .d_upload_img {
    box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
    border-radius: 5px;
    margin-top: 40px;
  }

  .file-upload-btn:active {
    border: 0;
    transition: all 0.2s ease;
  }

  .file-upload-content {
    display: none;
    text-align: center;
  }

  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }

  .image-upload-wrap {
    position: relative;
  }

  .image-title-wrap {
    padding: 0 15px 31px 0px;
    color: #222;
  }

  .drag-text {
    text-align: center;
  }

  .drag-text img {
    margin-bottom: 5px;
  }

  .drag-text h3 {
    font-weight: 100;
    text-transform: uppercase;
    color: #a2c760;
    padding: 60px 0;
  }

  .file-upload-image {
    padding: 2px;
    width: 300px;
    height: 200px; /* set the height of the element */
    object-fit: cover; /* scale the image to cover the element */
  } 

  .d_create_event_form {
    padding: 18px 0 0 0;
  }

  /* .form-control {
        border: 1px solid #E0E0E0 !important;
        border-radius: 5px;
        height: 50px;
    } */

  /* .d_free_or_paid {
    padding-top: 20px;
  } */

  .d_free_or_paid .d_title_box {
    margin-bottom: 18px;
  }

  .d_free_or_paid .d_main_title {
    font-size: 18px;
  }

  /****custome radio******/

  .d_custome_raido {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .d_custome_raido input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .d_custome_raido .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  }

  .d_custome_raido:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .d_custome_raido input:checked ~ .checkmark {
    background-color: #fff;
  }

  .d_custome_raido .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .d_custome_raido input:checked ~ .checkmark:after {
    display: block;
  }

  .d_custome_raido .checkmark:after {
    top: 3.2px;
    left: 3.2px;
    width: 11.6px;
    height: 11.7px;
    border-radius: 50%;
    background: #a2c760;
  }

  /* media query for tab or less screen device */

  /* private event  */

  .d_private_event .d_main_sub_title {
    font-weight: 500;
    font-size: 15px;
    color: #a2c760;
  }
  .form_label {
    font-size: 16px;
    color: #221d20;
    margin-bottom: 10px;
  }

  .location_select .form-control {
    background-color: transparent;
    z-index: 2;
    position: relative;
  }

  .location_select {
    position: relative;
    background-color: #fff;
  }

  .location_select::after {
    content: "";
    /* background-image: url(../images/icons/down-arrow.svg); */
    display: block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    position: absolute;
    top: 18px;
    right: 18px;
    z-index: 1;
  }

  .d_find_event .d_product_box {
    background: transparent;
    box-shadow: none;
    overflow: hidden;
    position: relative;
  }

  .d_find_event .d_hangout_box .d_product_img {
    height: 170px;
    padding: 0;
    width: 170px;
  }

  .d_find_event .d_product_img img {
    border-radius: 50% !important;
    border: 4px solid #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
  }

  .d_find_event .d_product_title {
    margin-top: 9px;
  }

  .d_hangout_box .d_product_img {
    height: 200px;
    padding: 0px 5px 5px 5px;
  }

  .d_hangout_box .d_product_img img {
    border-radius: 5px;
  }

  .d_hangout_box .d_product_title {
    color: #221e20;
  }

  .d_event_expert {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .d_left_panal {
    height: 100%;
  }
  .d_beer_product_cnt_title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 7px;
  }

  .d_beer_product {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
    border-radius: 5px;
  }

  .d_beer_product_cnt_rats strong {
    color: #2f2f2f;
  }

  .d_beer_product_cnt_link {
    color: #a2c760;
  }

  .d_beer_product_img {
    position: relative;
  }

  .d_beer_product_img figure {
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    overflow: hidden;
    height: 134px;
  }

  .d_beer_product_img figure img {
    height: 100%;
    object-fit: cover;
  }
  .d_add_new_product .d_beer_product_cnt_title {
    font-size: 16px !important;
    margin-bottom: 23px;
  }
  .d_order_summry .box_padding {
    padding: 15px 20px;
  }

  .coupon_panel .d_order_summry .box_padding {
    padding: 13px;
  }

  .d_order_summry .d_textarea {
    height: 65px;
    font-size: 13px;
    color: #757575 !important;
    letter-spacing: 0.01em;
  }

  .d_card_icon_count {
    width: 20px;
    height: 20px;
    background-color: #a2c760;
    bottom: -7px;
    color: #fff;
    border: 1px solid #fff;
    font-size: 14px;
  }

  .d_card_icon {
    margin-right: 15px;
  }

  .coupon_panel .d_card_icon img {
    border: 1px solid #d1d1d1;
    border-radius: 5px;
  }

  .coupon_panel .d_card_icon {
    width: 45px;
    height: 45px;
  }

  .d_order_border_btm {
    border-bottom: 1px solid #dadada;
  }

  .d_order_list_name li img {
    margin-right: 10px;
  }

  .d_order_list_name li .p_gray {
    font-size: 13px !important;
  }

  .d_order_list_name .vage_curcul {
    top: 0;
    right: auto;
  }

  .d_order_list_name .vage_curcul {
    top: 0;
    right: auto;
    margin-right: 7px;
  }

  .d_order_list_name li {
    display: inline-block;
    vertical-align: top;
  }

  .d_order_list_name li p {
    font-size: 14px;
    line-height: 21px;
    color: #505050;
  }

  .d_order_list_name li p span {
    font-family: "Averia Serif Libre", cursive !important;
    color: #222222;
    font-weight: 700;
  }

  .vage_curcul {
    background: #ffffff;
    border: 1px solid #a2c760;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 11px;
    right: -9px;
  }

  .vage_curcul em {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #a2c760;
    border-radius: 50%;
  }

  .g-add .form {
    width: 100%;
    padding-top: 0;
    display: flex;
    border: 1px solid #dddddd;
    border-radius: 3px;
  }

  .g-add .value-button {
    margin: 0px;
    width: 40px;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #000;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .g-add input.number {
    text-align: center;
    border: none;
    margin: 0px;
    width: 100%;
    vertical-align: middle;
    color: #000;
    padding: 4px 0;
  }

  .g-add .value-button:hover {
    cursor: pointer;
  }

  .g-add form #decrease {
    margin-right: -4px;
    border-radius: 8px 0 0 8px;
  }

  .g-add form #increase {
    margin-left: -4px;
    border-radius: 0 8px 8px 0;
  }

  .g-add form #input-wrap {
    margin: 0px;
    padding: 0px;
  }

  .delete {
    margin-left: 11px;
    color: #d43838;
    font-size: 18px;
  }

  .d_event_list {
    column-count: 2;
    margin-top: 12px;
  }

  .form_check_box label {
    color: #000;
    font-size: 14px;
  }

  .d_event_list .form_check_box_label .checkmark {
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #afafaf;
  }

  .d_event_list .form_check_box_label .checkmark:after {
    left: 4px;
    top: 0px;
    width: 6px;
    height: 11px;
  }

  .d_event_list .form_check_box label {
    color: #353535;
    font-size: 14px;
  }

  .form_check_box_label {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .form_check_box_label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .form_check_box_label .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #d6d6d6;
    border-radius: 2px;
  }

  .form_check_box_label:hover input ~ .checkmark {
    background-color: #d6d6d6;
  }

  .form_check_box_label input:checked ~ .checkmark {
    background-color: #a2c760;
  }

  .form_check_box_label .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .form_check_box_label input:checked ~ .checkmark:after {
    display: block;
  }

  .form_check_box_label .checkmark:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .form_check_box label {
    color: #000;
    font-size: 14px;
  }

  .checkbox_link {
    color: #1ca3ef;
    text-decoration: underline !important;
    display: inline-block;
  }

  .d_paid {
    font-size: 15px;
    font-weight: 400;
    vertical-align: top;
    color: #000;
  }
  /* login part */

  .login_page .d_create_event {
    width: 410px;
    margin: 7% auto;
  }

  .login_page .d_create_event input {
    width: 81%;
  }

  .d_comn_btn {
    padding: 5px;
    display: inline-block;
    text-align: center;
    background-color: #a2c760;
    /* text-transform: uppercase; */
    color: #fff;
    font-size: 17px;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    border: 0;
    width: 100%;
  }

  .login_page .d_create_event {
    background-color: white;
  }
  .foot-login {
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    border-top: 1px solid #888888;

    padding: 3%;
  }

  .mug_club .card {
    margin: 2%;
  }
  #mugClub-form input {
    background-color: #8888880d;
    outline: none;
  }
  #mugClub-form textarea {
    background-color: #8888880d;
  }

  .img_dimen {
    color: #86ab43;
    font-size: 15px;
    margin-top: 5px;
  }

  #mug-c-card img {
    width: 100%;
    height: auto;
    border-radius: 1px;
  }
  .hglight-title {
    font-weight: 700;
    text-align: center;
  }

  .hglight {
    font-weight: 600;
    font-size: 17.4px;
  }

  p {
    font-size: small;
  }
  #d-share-gift .d_product_img {
    height: 200px;
  }

  .control-input {
    width: 100%;
    padding: 1.3%;
    border-radius: 5px;
    border-color: rgba(85, 85, 85, 0.16);
  }

  .d_product_img {
    width: 100%;

    height: 160px;
  }

  .profile-form .form-group {
    margin-bottom: 3.5%;
  }

  .pick-up {
    background-color: #e2f3eb50;
    margin: 2%;
    padding: 2%;
    width: 50%;
    height: 2%;
    border-radius: 5px;
    border-color: #a2c760;
  }
  #profile {
    margin: 3%;
    margin-left: 15%;
  }

  #ordernowbtn {
    background-color: #a2c760;
    margin-left: 6%;
  }

  .form-group img {
    position: relative;

    width: 20px;
  }

  @media only screen and (min-width: 900px) {
    .d_locat_box {
      width: auto;
    }

    #price-on-pic {
      display: none;
    }
    .bell {
      display: none;
    }

    .login_page input {
      width: 500px;
      margin-left: 2%;
      height: 40px;
      border-radius: 5px;
      padding: 1%;
    }

    /* my profile page */
    .profile-page {
      margin: 1.2rem auto;
      width: 40%;
    }
    .order-page {
      margin: 1.2rem auto;
      width: 50%;
    }
    .profile-form {
      padding: 2px 10%;
    }
  }
  #more {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    button.d_comn_btn.btnChechk {
      width:50% !important
    }
    body {
      background-color: rgba(255, 255, 255, 0.685);
      width: auto !important;
      overflow-x: hidden !important;
    }
    .food-menu-container button, .quantity-container {
      bottom: -18%;
    }
    .food-menu-container img {
      width: 129px;
      height: 129px;
      border-radius: 5px;
  }
#mob_res_loc {
  padding: 5px !important;
  margin-top: -17px !important;
  background-color: #edf7dd !important;
}
    .food-menu-container .description {
      width: 90%;
      font-size: 14px;
      color: rgb(58, 52, 52);
  }
  .food-menu-container {
    padding-left: 3.3% !important;
    padding: 3%;
    padding-bottom: 5%;
  }
    .gallery img {
      border-radius: 15px;
      height: 160px;
    }
    .share-like {
      width: 28px;
      height: 24px;
      text-align: center;
      border-radius: 5px;
      background-color: #a2c760;
      color: white;
      margin: 2%;
    }

    .d_feat_event_list li {
      display: block;
      width: 100%;
      margin-left: -25px;
      font-size: 12px;
      color: #636363e0;
    }

    .h-food-menu {
      border-top: 1px solid #c5c6d0;
      /* padding-left: 2%; */
      background-color: #f8f9fa;
      padding: 2%;
    }
    .d_feat_event_title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 15px;
      color: #221e20;
      margin-bottom: 8px;
      padding-left: 2%;
    }
    .d_feat_event_list li img {
      width: 11px;
      margin-right: 3%;
    }

    .d_feat_event_title2 {
      font-weight: 600;
      font-size: 18px;
      /* line-height: 15px; */
      color: #221e20;
      margin-top: 10px;
      padding-left: 2%;
    }

    .d_hangout {
      margin-top: 30px;
      min-height: 40vh;
    }
    .d_main_panel_review {
      padding-top: 0px;
    }
    .ph-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 50px;
      box-shadow: 0px 0px 5px #888888;
      color: black;
      background-color: white;
      padding-bottom: 4%;
      padding-left: 2%;
      padding-right: 2%;
    }
    .active,
    .c1:hover {
color: #a2c760 !important;
background-color: #ffffff !important;
    }

    .c1 {
      width: 71%;
      padding: 8px;
      text-align: center;
    }
    .c2 p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      padding: 8px;
      text-align: center;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      font-size: 16px;
      color: #333;
      margin-top: -29px !important;
      margin-left: 16px !important;
  }
    
    #end-footer {
      display: none;
    }
    .search_order {
      margin: 0% auto;
      height: 35px;
      border: 1px solid #a2c760;
      border-radius: 5px;
      width: 93%;
      display: flex;
      align-items: center;
      padding: 5px; /* Add padding to the form */
    }
    
    .search_order input {
      flex: 1; /* Take up remaining space */
      border: none;
      outline: none;
      padding-left: 10px !important;
      border-radius: 5px;
padding: 0;
    }
    
    .search_order button {
      background-color: #a2c76000;
      border: none;
      outline: none;
      padding: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      cursor: pointer;
    }
    

    .search_order button img {
      width: 20px;
    }
    
    /* Add a transition for a smoother effect */
    .search_order button:hover {
      background-color: #a2c760;
    }
    
    .d_event_name {
      top: -7px;
      position: relative;
      color: #a2c760;
      font-size: 11px;
      padding-left: 2%;
    }
    .wh {
      margin-bottom: 5%;
      padding-bottom: 12%;
      margin-left: 0;
      margin-right: 0;
    }


    .slideHangOut {
      padding-left: 3%;
      padding-right: 3%;
    }
    .d_hangout_box .d_product_img {
      height: 120px;
      padding: 0px;
    }

    p.d_product_title {
      line-height: 5px;
    }
    .h-1st-col {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      padding: 2%;
      /* border: 2px solid #dedede ; */
      margin: 3%;
    }

    .home-1st-col p {
      font-weight: 600;
      text-align: center;
      margin: 3%;
    }

    .pick-up {
      width: 90%;
      margin-left: 4%;
    }
    #ordernowbtn {
      /* background-color:#80CB2B; */
      margin-left: 1%;
      margin-top: 5%;
    }
    #profile {
      margin: 3%;
      margin-left: 1%;
    }

    .control-input {
      width: 100%;
      padding: none;
      border-radius: none;
      border-color: none;
      border: none;
    }

    .form-group {

      margin-top: 2%;
      padding-bottom: 2%;
    }

    .form-group img {
      position: relative;

      width: 16px;
    }


    #footer-desktop {
      display: none;
    }
    .h-left img {
      width: 25px;
      padding: 1%;
    }

    .h-left {
      /* margin: 1px; */
      padding-top: 16px;
      text-align: end;
    }

    .login_page .d_create_event {
      width: 310px;
      margin: 0;
    }

    .form-group input {
      float: none;
      width: 96%;
      outline: none;
      /* border: none; */
      margin-left: 3%;
    }

    .login_page .d_main_panel {
      padding: 50px 0;
    }

    /* event save page in mobile view */
    .d_create_event {
      width: 100%;
      margin-bottom: 10%;
    }

    .back-button-header {
      padding: 2%;

      height: 70px;
      width: 100%;
      border-bottom: 3px solid rgba(146, 145, 145, 0.679);
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;
    }

    .d_feat_event_cnt {
      padding: 7px 4px 0px 5px;
    }
    .d_reminder {
      display: none;
    }

    #price-on-pic {
      float: right;
      position: relative;
      top: -20px;
      background-color: #edf7ddef;
      border-radius: 10px;
      padding: 0 2%;
      margin-right: 2%;
      height: 25px;
    }

    .d_feat_event_name {
      top: -28px;
      position: relative;
    }
    .d_feat_event_list {
      position: relative;
      top: -32px;
    }
    .d_feat_event_btn {
      position: relative;
      margin-top: -50px;
      height: 38.5px;
      padding-left: 5%;
      border-top: 0px solid #dbe3e7;
    }
    .ph-none {
      display: none;
    }
    .d_main_panel {
      padding: 4px 0;
    }
    .d_margin {
      margin-top: 100px;
    }

    .d_art_craft span {
      box-shadow: none;
      border-radius: none;
      padding: 0px 0px 10px 6px;
      margin-right: none;
      font-size: 15px;
    }
    .d_art_craft {
      margin: 0px 0;
      position: relative;
      top: -20px;
    }
    .d_art_craft img {
      width: 12px;
    }

    /* modal sidebar tshirt size */
  }

  @media screen and (min-width: 800px) {
    .back-button-header {
      display: none;
    }
    .ds-none {
      display: none;
    }
  }

  /* order menu cart */
  .left_category_nav .nav-item .nav-link.active p:before,
  .left_category_nav .nav-item .nav-link:hover p:before {
    background: #80cb2b;
  }
  .left_category_nav
    ul.category_scrollspy-ul
    .nav-item
    .nav-items-sub
    a.active {
    color: #80cb2b;
  }
  .left_category_nav .nav-item .nav-link.active p {
    color: #80cb2b;
  }

  .left_category_nav {
    background: #fff;
    border-radius: 6px;
    position: -webkit-sticky;
    position: sticky;
    top: 110px;
    margin: 0 0 20px 0;
  }
  .left_category_nav {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .page-content-list .col.col-left-sidebar .left_category_nav ul {
    list-style: none; /*-webkit-transition: all 0.5s ease;-moz-transition: position 10s;-ms-transition: position 10s; -o-transition: position 10s;transition: all 0.5s ease;*/
  }
  .left_category_nav .nav-link {
    color: #333333;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
  }
  .left_category_nav .nav-link p {
    margin: 0;
    padding: 10px 15px 10px 20px;
    color: #333333;
    font-weight: 500;
    position: relative;
    text-transform: none;
  }
  .left_category_nav .nav-item .nav-link.active {
    color: #c52031;
  }
  .left_category_nav .nav-item .nav-link.active p {
    color: #80cb2b;
  }
  .left_category_nav .nav-item .nav-link.active p:before,
  .left_category_nav .nav-item .nav-link:hover p:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 4px;
    z-index: 0;
    display: inline-block;
    left: 0px;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
  .left_category_nav .nav-item .nav-link.active + .ul-sub {
    display: block;
  }
  .left_category_nav li:last-child.nav-item a.nav-link {
    border-bottom: none;
  }
  .left_category_nav ul.category_scrollspy-ul .nav-item .nav-items-sub {
    display: none;
    border-bottom: 1px solid #eeeeee;
  }
  .left_category_nav ul.category_scrollspy-ul .nav-item .active .nav-items-sub {
    display: block;
    background: #efefef;
    border: 1px solid #efefef;
    margin: 0;
    padding: 10px 0 10px 0;
  }
  .left_category_nav
    ul.category_scrollspy-ul
    .nav-item
    .active
    + .left_category_nav
    ul.category_scrollspy-ul
    .nav-item
    .nav-items-sub {
    display: block;
  }
  .left_category_nav ul.category_scrollspy-ul .nav-item .nav-items-sub a {
    display: block;
    padding: 10px 10px 10px 30px;
    color: #333;
    background: #f5f5f5;
    text-transform: none;
  }
  .left_category_nav
    ul.category_scrollspy-ul
    .nav-item
    .nav-items-sub
    a.active {
    color: #c52031;
    background: #ececec;
  }

  .parentcat p {
    font-size: large;
    font-weight: 900px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 15px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .food-menu {
    /* border-top: 1px solid #c5c6d0; */
    border-top: 1px dotted #c5c6d0;
    padding: 2%;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: " ";
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  #non-veg input:checked + .slider {
    background-color: #cd4518;
  }
  input:checked + .slider {
    background-color: #a2c760;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #a2c760;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(22.7px);
  }
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  ul.category_scrollspy-ul {
    padding-left: 0;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.626);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .cart-icon {
    text-decoration: none;
    padding: 1%;
    background-color: #80cb2b;
    color: white;
    width: auto;
    height: auto;
    border-radius: 50%;
    position: fixed;
    display: flex;
    justify-content: center;
    margin-left: 95%;
    margin-top: 490px;
  }
  /* mkdfnevbr */
  /* dfnnnrbkf */
  /* dknevrb */

  #cart-count {
    text-decoration: none;
    /* position: fixed; */
    margin-top: -38px;
    margin-left: 1.2%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    background-color: rgba(243, 30, 30, 0.749);
  }

  .cart_footer {
    width: 110%;
    position: sticky;
    background-color: white;
    border-radius: 5px;
    margin-left: 15px;
  }
  /* .cart_footer{
      width: 100%;
    background: rgb(194, 106, 106);
    height: 0px;
    min-height: 0px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 600;
    color: #282c3f;
    padding: 0 30px;
    z-index: 1;
    position: sticky;
    width: 100%;
    bottom: 0;

} */

  ul.d_order_list_name.mb-0 {
    padding-left: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* -webkit-appearance: none; */
    margin: 0;
  }

  /* Firefox */
  /* input[type=number] {
  -moz-appearance: textfield;
} */
  .custom-control {
    font-weight: 700;
    border: 1px solid #ced4da;
    border-radius: 5px;
    width: 200px;
    padding: 2%;
    display: flex;
    /* background-color: #15824B; */
    /* margin-top: 2%; */
    /* margin-left: 0; */
  }
}

/* popup modal 29/11/22 */

.d_box {
  width: 100%;
  border-radius: 2px;
  padding: 1%;
  background-color: #a2c760;
}

.custom-control p {
  position: relative;
  display: block;
  min-height: 1.5rem;

  font-size: 20px;
  padding-top: 5%;
}
.custom-control input {
  width: 20px;
  height: 20px;
  margin: 2%;
}

.custom-control label {
  margin-top: 1.1%;
}

.custom-control-label {
  width: 50%;
  /* padding-top: 1%; */
}
/* .sidebar:hover {
  background-color: rgb(140, 140, 252);
  color: white;
} */
.active,
.sidebar:hover {
  background-color: #a2c760;
  color: white;
}
.sidebar {
  padding: 2%;
  text-align: center;
  margin: 2%;
}

ul.event_dtl_footer_list {
  padding-left: 1rem;
}
ul.event_dtl_footer_list a {
  text-decoration: none;
}
.radio-b-n input{
  width: 20px !important;
}
.cart-log-sign input {
  background-color: transparent;
  line-height: normal;
  outline: none;
  width: 91%;
  height: 36px;
  margin: 0 5% 2% 5%;
  font-size: 17px;
  margin-top: 1.4%;
  padding: 0 20px;

  border-radius: 5px;
  box-shadow: none;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  border: 1px solid #ced4da;
}

.radio-b-n select{
  width: 20px !important;
}
.cart-log-sign select {
  background-color: transparent;
  line-height: normal;
  outline: none;
  width: 91%;
  height: 36px;
  margin: 0 5% 2% 5%;
  font-size: 17px;
  margin-top: 1.0%;
  padding: 0 20px;

  border-radius: 5px;
  box-shadow: none;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  border: 1px solid #ced4da;
}

.l-cart-pg {
  margin: 0px auto;
}
/* .C6mS- {
  border: 1px solid #a2c760;
  color: #a2c760;
  margin-right: 20px;
  text-align: center;
  padding: 8px 35px 9px;
  line-height: 1.2;
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
} */

.btn-cart-signup {
  background-color: #a2c760;
  /* #60b246; */
  color: white;
}
/* .ReactCrop__image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
} */

.ReactCrop img {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.select-city select{
 width: 100%;
 border-radius: 10px;
 padding: 2%;

}

.select-city button{
  margin: 10px auto;
  display: flex;
  justify-content: center;
  padding: 1% 3% 1% 3%;
  background-color: #a2c760;
  color: #e5f7d1;
  border-radius: 6px;
  border: none;
  font-size: 18px;
}
.select-city button:hover{

  background-color: #678a29;
  color: white;
  
}
.add-on-more-btn{
  border: none;
  outline: none;
  width: 100px;
  border-radius: 3px;
  background-color: #a2c760;
  color: #ffffff;
}

.pincode {
  background-color: #a2c760;
    border-radius: 14px;
    display: block;
    width: 112px;
    height: 50px;
    margin-left: 177px;
    margin-top: 5px;
}

.scrollable-cropper-container {
  max-height: 400px; /* Set the maximum height of the container */
  overflow-y: auto;  /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrolling */
}
.d_feat_event_btn_updated {
  width: 50%;
  padding: 13px 0;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}